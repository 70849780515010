'use strict';

$(document).ready(() => {
  /**
   * Function to start the session
   * @param startSession
   */
  $('#zorgidlogin').on('click', function () {
    const zorgIdConnector = new ZorgIdConnector(window.notifier, window.medimo.WebSocket);
    zorgIdConnector.login();
  });
});
